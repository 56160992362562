import React, { useContext } from "react";
import styles from "./index.module.scss";
import { SettingsContext } from "../../providers/SettingsProvider";
import { AuthContext } from "../../providers/AuthProvider";
import { Badge } from "react-bootstrap";
import { isMe, getSplittedName } from "../../helpers/user";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { rndInt, getTeamLogoUrl } from "../../helpers/utils";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1201 },
    items: 5,
  },
  between: {
    breakpoint: { max: 1200, min: 1025 },
    items: 4,
  },
  largeDesktop: {
    breakpoint: { max: 1024, min: 769 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 768, min: 641 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 640, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const LeadersCarousel = () => {
  const { standings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const isVisible = standings.length > 0;

  const navigate = useNavigate();
  return (
    isVisible && (
      <div>
        <h5 className={styles.title}>
          <div>
            <i className="bi bi-bar-chart"></i>&nbsp;ЛІДЕРИ
          </div>
          <Button
            className={styles.allLink}
            variant="link"
            onClick={() => navigate("/standings/-1")}
          >
            Всі учасники
          </Button>
        </h5>
        <Carousel
          slidesToSlide={3}
          itemClass="carousel-item-border"
          responsive={responsive}
        >
          {standings.map((item) => (
            <div
              key={item.UserId}
              className={styles.listItem}
              onClick={() => navigate(`/details/${item.UserId}`)}
            >
              <div className={styles.person}>
                <img className={styles.logo} src={item.Logo} alt="" />
                <div className={styles.caption}>
                  {getSplittedName(item.FullName)}
                </div>
                {isMe(user?.me, item.UserId) && (
                  <i className={`${styles.meIcon} bi bi-star-fill`}></i>
                )}

                <Badge style={{ padding: "2px 8px" }} bg="success">
                  {item.Points}
                </Badge>
              </div>

              <div className={styles.favTeam}>
                <i className="bi bi-suit-heart"></i>
                <img
                  style={{ width: 22, objectFit: "cover", filter: "sepia(1)" }}
                  src={getTeamLogoUrl(item.FavTeamId, rndInt())}
                  alt=""
                />
              </div>

              <Badge bg="primary" className={styles.rank}>
                {item.Rank} МІСЦЕ
              </Badge>
            </div>
          ))}
        </Carousel>
      </div>
    )
  );
};

export default LeadersCarousel;
