import { useQuery } from "react-query";
import API from "../api";
import { SettingsContext } from "../providers/SettingsProvider";
import { useContext } from "react";

const isEnabled = ({ page, loaded, monthIndex }) => {
  if (page === "stats") return true;
  return page === "home" ? loaded === true : !Boolean(monthIndex);
};

function useFetchSettings({ page, loaded }) {
  const {
    monthIndex,
    setMonthIndex,
    setCurrentRound,
    setActiveDeadline,
    setRoundMatches,
    setRoundDeadline,
    setTeams,
    setMaintenanceMode,
    setNumberOfTeamPlayers,
    setLastRound,
    setIsWinterBreakEnabled,
  } = useContext(SettingsContext);

  const { isLoading: isLoadingSettings } = useQuery(
    "query-settings",
    async () => {
      return API.fetchSettings();
    },
    {
      retry: 2,
      enabled: isEnabled({ page, loaded, monthIndex }),
      onSuccess: (res) => {
        setMonthIndex(res.data.monthIndex);
        setCurrentRound(res.data.currentRound);
        setActiveDeadline(res.data.isActiveDeadline);
        setRoundMatches(res.data.currentRoundMatches);
        setRoundDeadline(res.data.roundDeadline);
        setTeams(res.data.teams);
        setMonthIndex(res.data.monthIndex);
        setMaintenanceMode(res.data.maintenanceMode);
        setNumberOfTeamPlayers(res.data.numberOfTeamPlayers);
        setLastRound(res.data.lastRound);
        setIsWinterBreakEnabled(res.data.isWinterBreakEnabled);
      },
      onError: (err) => {
        console.log(err.response?.data || err);
      },
    }
  );
  return { isLoadingSettings };
}

export default useFetchSettings;
