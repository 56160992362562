import React, { useContext } from "react";
import styles from "./index.module.scss";
import { SettingsContext } from "../../providers/SettingsProvider";
import { AuthContext } from "../../providers/AuthProvider";
import { Badge } from "react-bootstrap";
import { isMe, getSplittedName } from "../../helpers/user";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { rndInt, getTeamLogoUrl } from "../../helpers/utils";
import Image from "react-graceful-image";

const LeadersList = () => {
  const { standings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const isVisible = standings.length > 0;

  const navigate = useNavigate();
  return (
    isVisible && (
      <div>
        <h5 className={styles.title}>
          <div>
            <i className="bi bi-bar-chart"></i>&nbsp;ЛІДЕРИ
          </div>
          <Button
            className={styles.allLink}
            variant="link"
            onClick={() => navigate("/standings/-1")}
          >
            Всі учасники
          </Button>
        </h5>
        {standings.map((item) => (
          <div
            key={item.UserId}
            className={styles.listItem}
            onClick={() => navigate(`/details/${item.UserId}`)}
          >
            <div className={styles.flexItem}>
              <Badge className={styles.rank} bg="success">
                {item.Rank}
              </Badge>
              <div className={styles.person}>
                <Image className={styles.logo} src={item.Logo} alt="" />
                <div className={styles.caption}>
                  {getSplittedName(item.FullName)}
                </div>
                {isMe(user?.me, item.UserId) && (
                  <i className={`${styles.meIcon} bi bi-star-fill`}></i>
                )}
              </div>
            </div>
            <div className={`${styles.flexItem} fav-team-column`}>
              <i className="bi bi-suit-heart"></i>&nbsp;
              <Image
                style={{ height: 30, objectFit: "cover", filter: "sepia(1)" }}
                src={getTeamLogoUrl(item.FavTeamId, rndInt())}
                alt=""
              />
            </div>
            <div className={styles.flexItem}>{item.Games}</div>
            <div style={{ fontWeight: "bold" }} className={styles.flexItem}>
              {item.Points}
            </div>
          </div>
        ))}
      </div>
    )
  );
};

export default LeadersList;
