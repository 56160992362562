export const getShortPosition = (position) => {
  const posMap = {
    Goalkeeper: "ГК",
    Defender: "ЗАХ",
    Midfielder: "ПІВ",
    Attacker: "НАП",
  };

  return posMap[position] || "";
};

export const getPosition = (position) => {
  const posMap = {
    Goalkeeper: "ГК",
    Defender: "ЗАХ",
    Midfielder: "ПІВ",
    Attacker: "НАП",
  };
  return posMap[position] || "Усі";
};

export const getPlayerById = (players = [], playerId) => {
  return players.find((player) => player.id === playerId) || {};
};

export const getPlayerLastName = (player) => {
  return player.LastName || player?.name || player.FirstName;
};

export const getPlayerShortTeam = (teams, player) => {
  return teams[player?.teamId]?.shortName;
};

export const isPlayerExist = (playerId) => {
  return playerId !== null;
};

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};

export const getRivalTeamIdByMatchId = (id, items, rivalIds, itemIndex) => {
  const idsWithoutCurrentItemId = items
    .filter((item, index) => itemIndex < index + 1)
    .map((item) => item.id);

  if (idsWithoutCurrentItemId.length === 0) return rivalIds[0];

  const onlyUniqueIds = idsWithoutCurrentItemId.filter(onlyUnique);

  return onlyUniqueIds.length > 1 || rivalIds.length === 1
    ? rivalIds[0]
    : rivalIds[1];
};

export const isThick = (id, items, index) => {
  if (index + 1 === items.length) return;
  return id !== items[index + 1].id;
};
export const getRivalIds = (playerStats = [], round) => {
  return playerStats.find((stat) => stat.Round === round)?.TeamRivalIds || null;
};
