import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import styles from "./index.module.scss";
import dayjs from "dayjs";
import { Badge, Button, Spinner, Placeholder } from "react-bootstrap";
import { getTeamLogoUrl, rndInt, debounce } from "../../helpers/utils";
import Image from "react-graceful-image";
import { SettingsContext } from "../../providers/SettingsProvider";
import FixtureView from "../../modals/FixtureView";
import Preview from "../../modals/Preview";
import API from "../../api";
import EditMatchDate from "../EditMatchDate";

const getFeedIcon = (feedUrl) => {
  if (/youtube/.test(feedUrl)) {
    return "yt.png";
  } else if (/megogo/.test(feedUrl)) {
    return "mg.png";
  } else {
    return "";
  }
};

const RoundMatches = ({
  currentRound,
  roundMatches = [],
  isAdmin = false,
  onHidePreviewModal = () => undefined,
  newRoundProcess,
  setNewRoundProcess = () => undefined,
  viewToggle = false,
}) => {
  const [roundItems, setRoundItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [fixtureViewModal, setFixtureViewModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [draftMode, setDraftMode] = useState(false);
  const { teams, selectedRound, setSelectedRound } = useContext(
    SettingsContext
  );

  const [json, setJson] = useState(null);

  const showFixtureModal = useCallback(() => {
    setFixtureViewModal(true);
  }, [setFixtureViewModal]);

  const showPreviewModal = useCallback(() => {
    setPreviewModal(true);
  }, [setPreviewModal]);

  const fetchFixture = useCallback(
    async ({ MatchId, Calculated }) => {
      try {
        setLoading(true);
        const response = await API.fetchPreview(MatchId, Number(Calculated));
        setJson(response.data);
        if (isAdmin) {
          showPreviewModal();
        } else {
          showFixtureModal();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        setLoadingIndex(null);
      }
    },
    [setLoading, showFixtureModal, showPreviewModal, isAdmin]
  );

  const hideFixtureModal = () => {
    setFixtureViewModal(false);
  };

  const hidePreviewModal = (needToRefresh) => {
    setPreviewModal(false);
    onHidePreviewModal(needToRefresh);
    setDraftMode(false);
    setJson(null);
  };

  const onBtnClick = ({ MatchId, Calculated }, index) => {
    setLoadingIndex(index);
    fetchFixture({ MatchId, Calculated });
  };

  const onAddClick = (fixtureId, homeTeamId, awayTeamId) => {
    setDraftMode(true);

    const storedJson = localStorage.getItem(fixtureId);

    setJson(
      storedJson
        ? JSON.parse(storedJson)
        : {
            fixture: {
              id: fixtureId,
            },
            teams: {
              home: {
                id: homeTeamId,
              },
              away: {
                id: awayTeamId,
              },
            },
            goals: {
              home: 0,
              away: 0,
            },
            events: [],
            lineups: [
              {
                team: {
                  id: homeTeamId,
                },
                startXI: [],
                substitutes: [],
              },
              {
                team: {
                  id: awayTeamId,
                },
                startXI: [],
                substitutes: [],
              },
            ],
          }
    );
    showPreviewModal();
  };

  const fetchRoundMatches = async (round) => {
    try {
      setLoadingItems(true);
      const response = await API.fetchRoundMatches(round);
      setRoundItems(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingItems(false);
    }
  };

  const fetch = useMemo(
    () =>
      debounce((round) => {
        if (round === null || newRoundProcess) return;
        if (round === currentRound) {
          setRoundItems(roundMatches);
        } else {
          fetchRoundMatches(round);
        }
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetch(selectedRound);
  }, [selectedRound, fetch, roundMatches, newRoundProcess, currentRound]);

  useEffect(() => {
    setSelectedRound(currentRound);
  }, [currentRound, setSelectedRound]);

  useEffect(() => {
    setRoundItems(roundMatches);
  }, [roundMatches]);

  const onPrevClick = () => {
    setNewRoundProcess(false);
    setSelectedRound((currRnd) => --currRnd);
  };

  const onNextClick = () => {
    setSelectedRound((currRnd) => ++currRnd);
  };

  return (
    <div
      style={{ marginTop: 8 }}
      className={`${!viewToggle ? "round-matches-cnt" : ""}`}
    >
      <h5 className={styles.title}>
        <div className={styles.roundPicker}>
          <i
            style={{
              visibility: isAdmin
                ? selectedRound === 0
                : selectedRound === 1
                ? "hidden"
                : "visible",
              left: 0,
            }}
            className="bi bi-chevron-left mr-8 hoverable"
            onClick={onPrevClick}
          ></i>
          <div className={styles.caption}>ТУР {selectedRound}</div>
          <i
            style={{
              visibility: selectedRound === currentRound ? "hidden" : "visible",
              right: 0,
            }}
            className="bi bi-chevron-right ml-8 hoverable"
            onClick={onNextClick}
          ></i>
        </div>
      </h5>

      {loadingItems ? (
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
          <Placeholder xs={12} style={{ height: 38, marginTop: 8 }} />
        </Placeholder>
      ) : (
        roundItems.map((roundMatch, index) => (
          <div key={roundMatch.MatchId} className={styles.listItem}>
            <div className={styles.cnt}>
              {isAdmin ? (
                <EditMatchDate match={roundMatch} currentRound={currentRound} />
              ) : (
                <Badge bg="success" className={styles.deadlineBadge}>
                  {dayjs(roundMatch.Deadline).format("DD.MM HH:mm")}
                </Badge>
              )}
              <div className={styles.details}>
                {isAdmin && (
                  <div className={styles.matchId}>{roundMatch.MatchId}</div>
                )}
                <div className={styles.score}>
                  <div
                    className={styles.detailsItem}
                    style={{ textAlign: "left" }}
                  >
                    <div className={styles.leftTeamCnt}>
                      <div
                        className={styles.cnt}
                        style={{ justifyContent: "flex-start" }}
                      >
                        <Image
                          className={styles.teamLogoLeft}
                          src={getTeamLogoUrl(roundMatch.FirstTeam, rndInt())}
                          alt=""
                        />
                        <span className={styles.shortName}>
                          {teams[roundMatch.FirstTeam]?.shortName}
                        </span>
                        <span className={styles.fullName}>
                          {teams[roundMatch.FirstTeam]?.name}
                        </span>
                      </div>
                    </div>{" "}
                    <Badge
                      bg="warning"
                      className={`${styles.scoreBadge} ${
                        roundMatch.Calculated ? styles.clickable : null
                      }`}
                      onClick={() => onBtnClick(roundMatch, index, true)}
                    >
                      {loading && loadingIndex === index ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <div style={{ fontSize: "1.3rem" }}>
                          {roundMatch.FirstScore !== undefined
                            ? roundMatch.FirstScore
                            : "-"}
                          :
                          {roundMatch.SecondScore !== undefined
                            ? roundMatch.SecondScore
                            : "-"}
                        </div>
                      )}
                    </Badge>
                    <div className={`${styles.cnt} ${styles.rightTeamCnt}`}>
                      <span className={styles.shortName}>
                        {teams[roundMatch.SecondTeam]?.shortName}
                      </span>
                      <span className={styles.fullName}>
                        {teams[roundMatch.SecondTeam]?.name}
                      </span>
                      <Image
                        className={styles.teamLogoRight}
                        src={getTeamLogoUrl(roundMatch.SecondTeam, rndInt())}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isAdmin ? (
              <div>
                <Button
                  disabled={loading && loadingIndex === index}
                  size="sm"
                  onClick={() => onBtnClick(roundMatch, index)}
                  style={{ height: 30 }}
                >
                  {roundMatch.Calculated ? (
                    <i className="bi bi-arrow-clockwise" />
                  ) : (
                    <i className="bi bi-calculator" />
                  )}
                </Button>
                {!roundMatch.Calculated && (
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() =>
                      onAddClick(
                        roundMatch.MatchId,
                        roundMatch.FirstTeam,
                        roundMatch.SecondTeam
                      )
                    }
                    style={{ height: 30, marginLeft: 8 }}
                  >
                    <i className="bi bi-pen" />
                  </Button>
                )}
              </div>
            ) : (
              <div className={styles.rightCnt}>
                <div style={{ display: "none" }} className={styles.feedCnt}>
                  <a
                    href={
                      roundMatch.FeedUrl ?? teams[roundMatch.FirstTeam]?.feedUrl
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      className={styles.feed}
                      src={getFeedIcon(
                        roundMatch.FeedUrl ??
                          teams[roundMatch.FirstTeam]?.feedUrl
                      )}
                      alt=""
                    />
                  </a>
                  <div
                    className={styles.feedLayer}
                    onClick={() => {
                      window.gtag &&
                        window.gtag("event", "feed", {
                          target: `${
                            teams[roundMatch.FirstTeam]?.shortName
                          } - ${teams[roundMatch.SecondTeam]?.shortName}`,
                        });
                      window.open(
                        roundMatch.FeedUrl ??
                          teams[roundMatch.FirstTeam]?.feedUrl,
                        "_blank"
                      );
                    }}
                  ></div>
                </div>
                <div style={{ position: "relative", top: 2 }}>
                  {roundMatch.Calculated ? (
                    <i
                      className="bi bi-check-circle-fill"
                      style={{ color: "green", fontSize: "1.3rem" }}
                    ></i>
                  ) : (
                    <i className="bi bi-hourglass"></i>
                  )}
                </div>
              </div>
            )}
          </div>
        ))
      )}
      <FixtureView
        show={fixtureViewModal}
        onHide={hideFixtureModal}
        json={json}
      />
      <Preview
        show={previewModal}
        onHide={hidePreviewModal}
        jsonRaw={json}
        selectedRound={selectedRound}
        draftMode={draftMode}
      />
    </div>
  );
};

export default RoundMatches;
