import React, { useContext, useEffect, useState, useMemo } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Page from "../components/Page";
import API from "../api";
import { useQuery } from "react-query";
import { AuthContext } from "../providers/AuthProvider";
import { AdminContext } from "../providers/AdminProvider";
import { SquadContext } from "../providers/SquadProvider";
import { SettingsContext } from "../providers/SettingsProvider";
import Squad from "../components/Squad";
import Loader from "../components/Loader";
import PlayerSearch from "../modals/PlayerSearch";
import Submit from "../components/Submit";
import InfoPanel from "../components/InfoPanel";
import RoundMatches from "../components/RoundMatches";
import LeadersList from "../components/LeadersList";
import LeadersCarousel from "../components/LeadersCarousel";
import Coach from "../components/Coach";
import PlayerInfo from "../modals/PlayerInfo";
import { getPlayerById } from "../helpers/player";
import { debounce } from "../helpers/utils";
import FavTeam from "../modals/FavTeam";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useSearchParams } from "react-router-dom";
import InvitePrivateLeague from "../modals/InvitePrivateLeague";
import ScrollToTop from "../components/ScrollToTop";
import useFetchSettings from "../hooks/useFetchSettings";
import useIsMobile from "../hooks/useIsMobile";

export const isPwa = () => {
  const mqStandAlone = "(display-mode: standalone)";
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    return true;
  }
  return false;
};

const Home = () => {
  const [viewToggle, setViewToggle] = useState(
    () => localStorage.getItem("squad_view") === "true"
  );
  const [playerInfoModal, setPlayerInfoModal] = useState(false);
  const [favTeamModal, setFavTeamModal] = useState(false);
  const [player, setPlayer] = useState(null);
  const [historicalSquad, setHistoricalSquad] = useState({});
  const [historicalDetails, setHistoricalDetails] = useState([]);
  const [historicalSquadLoading, setHistoricalSquadLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [inviteModal, setInviteModal] = useState(false);

  useEffect(() => {
    if (searchParams.get("invite")) setInviteModal(true);
  }, [searchParams]);

  const onHideInviteModal = () => {
    if (searchParams.has("invite")) {
      searchParams.delete("invite");
      setSearchParams(searchParams);
    }

    setInviteModal(false);
  };

  const {
    squad,
    setSquad,
    setSquadScores,
    setSquadSnapshot,
    clearAction,
  } = useContext(SquadContext);

  const [playerSearchVisible, setPlayerSearchVisible] = useState(false);
  const {
    players,
    setSearchPosition,
    setSearchPlayer,
    setSearchTeam,
  } = useContext(AdminContext);
  const {
    setStandings,
    setActivePlayersNumber,
    currentRound,
    roundMatches,
    setMainInfo,
    setMonthInfo,
    setPrevRoundInfo,
    setLeagueInfo,
    setPrevMonth,
    setPrevLeague,
    setPrevMain,
    selectedRound,
    lastRound,
  } = useContext(SettingsContext);

  const { loaded, user } = useContext(AuthContext);

  const { isLoading: isLoadingSquad } = useQuery(
    "query-squad",
    async () => {
      return API.fetchSquad(user?.me?.userId);
    },
    {
      retry: 2,
      enabled: loaded === true,
      onSuccess: (res) => {
        setSquad(res.data.squad);
        setSquadScores(res.data.scores);
        setSquadSnapshot(res.data.squad);
      },
      onError: (err) => {
        console.log(err.response?.data || err);
      },
    }
  );

  const { isLoadingSettings } = useFetchSettings({
    page: "home",
    loaded,
  });

  const { isLoading: isLoadingStandings } = useQuery(
    "query-standings",
    async () => {
      return API.fetchStandings(
        null,
        10,
        user?.me?.userId,
        user?.me?.favTeamId
      );
    },
    {
      retry: 2,
      enabled: loaded === true,
      onSuccess: (res) => {
        setStandings(res.data.standings);
        setMainInfo(res.data.main);
        setMonthInfo(res.data.month);
        setPrevRoundInfo(res.data.prevRound);
        setLeagueInfo(res.data.league);
        setPrevMonth(res.data.prevMonth);
        setPrevLeague(res.data.prevLeague);
        setPrevMain(res.data.prevMain);
        setActivePlayersNumber(res.data.activePlayersNumber);
      },
      onError: (err) => {
        console.log(err.response?.data || err);
      },
    }
  );

  const onHidePlayerSearch = () => {
    setPlayerSearchVisible(false);
    clearAction();
    setSearchPosition("All");
    setSearchPlayer(null);
    setSearchTeam(-1);
  };

  const onShowPlayerSearch = () => {
    setPlayerSearchVisible(true);
  };

  const onToggle = (state) => {
    setViewToggle(state);
  };

  const onHidePlayerInfoModal = () => {
    setPlayerInfoModal(false);
  };

  const onShowPlayerInfo = (playerId) => {
    const newPlayer = getPlayerById(players, playerId);
    setPlayer(newPlayer);
    setPlayerInfoModal(true);
  };

  const showFavTeamModal = () => {
    setFavTeamModal(true);
  };

  const onHideFavTeamModal = () => {
    setFavTeamModal(false);
  };

  useEffect(() => {
    if (loaded && user?.me?.favTeamId === 0 && !window.favTeamSaved) {
      showFavTeamModal();
    }
    window.sessionStorage.removeItem("leagueId");
  }, [loaded, user]);

  useEffect(() => {
    localStorage.setItem("squad_view", viewToggle);
  }, [viewToggle]);

  const fetchHistory = useMemo(
    () =>
      debounce(async (round) => {
        try {
          setHistoricalSquadLoading(true);
          const response = await API.fetchHistory(round);
          setHistoricalSquad(response.data.Squad);
          setHistoricalDetails(response.data.Details);
        } catch (err) {
          console.log(err);
        } finally {
          setHistoricalSquadLoading(false);
        }
      }, 1000),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedRound || selectedRound === currentRound) return;
    fetchHistory(selectedRound);
  }, [selectedRound, currentRound, fetchHistory]);

  const isHistoricalRound = useMemo(() => {
    if (!selectedRound || selectedRound === currentRound) return false;
    return selectedRound < currentRound;
  }, [selectedRound, currentRound]);

  const isSeasonFinished = lastRound < currentRound;

  const isMobile = useIsMobile();

  const Leaders = () => {
    if (isMobile) return <LeadersList />;
    return viewToggle ? <LeadersList /> : <LeadersCarousel />;
  };

  return (
    <PullToRefresh
      resistance={5}
      isPullable={isPwa()}
      pullingContent={
        <Page zeroPadding={true}>
          <i className="bi bi-chevron-double-down"></i>&nbsp;Тягніть вниз щоб
          оновити
        </Page>
      }
      onRefresh={() => Promise.resolve().then(window.location.reload())}
    >
      <Page isHome={true}>
        {isLoadingSquad ||
        isLoadingSettings ||
        isLoadingStandings ||
        !loaded ||
        !players?.length ? (
          <Loader
            text={loaded ? "Завантажуємо ігрові дані..." : "Авторизуємо..."}
          />
        ) : (
          <Grid fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <InfoPanel />
            <Coach
              isHistoricalRound={isHistoricalRound}
              onToggle={onToggle}
              showFavTeamModal={showFavTeamModal}
              defaultViewToggle={viewToggle}
            />
            <Row style={{ marginBottom: 8 }}>
              <Col xs={12} md={6}>
                <Squad
                  isHistoricalRound={isHistoricalRound}
                  historicalDetails={historicalDetails}
                  historicalSquad={historicalSquad}
                  onToggle={onToggle}
                  defaultViewToggle={viewToggle}
                  squad={squad}
                  onShowPlayerSearch={onShowPlayerSearch}
                  onShowPlayerInfo={onShowPlayerInfo}
                  viewToggle={viewToggle}
                  historicalSquadLoading={historicalSquadLoading}
                />
              </Col>
              <Col xs={12} md={6}>
                {!isSeasonFinished && (
                  <RoundMatches
                    currentRound={currentRound}
                    roundMatches={roundMatches}
                    viewToggle={viewToggle}
                  />
                )}
                {Leaders()}
              </Col>
            </Row>
            <Submit isPwa={isPwa()} isHistoricalRound={isHistoricalRound} />
            <PlayerSearch
              onShowPlayerInfo={onShowPlayerInfo}
              show={playerSearchVisible}
              onHide={onHidePlayerSearch}
            />
          </Grid>
        )}
        <PlayerInfo
          show={playerInfoModal}
          onHide={onHidePlayerInfoModal}
          player={player}
        />
        <FavTeam
          show={favTeamModal}
          onHide={onHideFavTeamModal}
          defaultTeamId={user?.me?.favTeamId}
        />
      </Page>
      {searchParams.get("invite") && (
        <InvitePrivateLeague
          show={inviteModal}
          onHide={onHideInviteModal}
          leagueId={searchParams.get("invite")}
        />
      )}
      <ScrollToTop />
    </PullToRefresh>
  );
};

export default Home;
