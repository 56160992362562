import React, { useEffect, useContext, useState, useCallback } from "react";
import Page from "../components/Page";
import { AuthContext } from "../providers/AuthProvider";
import API from "../api";
import StandingsView from "../components/StandingsView";
import { Button, Spinner } from "react-bootstrap";
import Loader from "../components/Loader";
import BackButton from "../components/BackButton";
import ScrollToTop from "../components/ScrollToTop";
import CreatePrivateLeague from "../modals/CreatePrivateLeague";
import { Dropdown } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getLeagueInviteUrl } from "../modals/CreatePrivateLeague";
import Message from "../components/Message";
import { SettingsContext } from "../providers/SettingsProvider";
import FindMe from "../components/FindMe";
import useFetchSettings from "../hooks/useFetchSettings";

const hiddenConfig = {
  //"8fe3e996-8daf-4164-9540-1e829464122c": ["62380410", "78541825"],
};

const getLeagueMembers = (selectedLeague) => {
  const { LeagueId, Members } = selectedLeague;
  const leagueHiddenMembers = hiddenConfig[LeagueId] ?? [];
  const parsedMembers = JSON.parse(Members);

  return parsedMembers.filter(
    (member) => !leagueHiddenMembers.includes(member)
  );
};

const filters = [
  {
    type: "SEASON",
    label: "Сезон",
  },
  {
    type: "MONTH",
    label: "Місяць",
  },
  {
    type: "PREV_ROUND",
    label: "Тур",
  },
  {
    type: "LIVE",
    label: "LIVE",
  },
];

const getFilterLabel = (type) => {
  return filters.find((filter) => filter.type === type)?.label;
};

const getFilterParameter = (selectedFilter, monthIndex, currentRound) => {
  switch (selectedFilter) {
    case "MONTH":
      return monthIndex;
    case "PREV_ROUND":
      return currentRound - 1;
    case "LIVE":
      return currentRound;
    default:
      return null;
  }
};

const PrivateLeagues = () => {
  const { user } = useContext(AuthContext);
  const { monthIndex, currentRound } = useContext(SettingsContext);

  const [standingItems, setStandingItems] = useState([]);
  const [createLeagueModal, setCreateLeagueModal] = useState(false);
  const [loadedItems, setLoadedItems] = useState(false);

  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingLeagues, setLoadingLeagues] = useState(true);

  const [leagues, setLeagues] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(filters[0].type);
  const [isAddBtnVisible, setIsAddBtnVisible] = useState(false);

  useFetchSettings({ page: "private-league" });

  const fetchLeagues = useCallback(async () => {
    if (!user?.me) return;
    try {
      setLoadingLeagues(true);
      const response = await API.fetchPrivateLeagues();
      setLeagues(response.data);
      setIsAddBtnVisible(
        response.data.filter((league) => league.OwnerId === user?.me?.userId)
          .length < 3
      );
      if (response.data.length > 0) {
        setSelectedLeague(
          (currentLeague) =>
            response.data.find(
              (league) => league?.LeagueId === currentLeague?.LeagueId
            ) ?? response.data[0]
        );
      } else {
        setSelectedLeague(null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingLeagues(false);
    }
  }, [setLeagues, setIsAddBtnVisible, user]);

  const fetchLeague = useCallback(
    async (members, selectedFilter) => {
      const filter = getFilterParameter(
        selectedFilter,
        monthIndex,
        currentRound
      );

      try {
        setLoadingItems(true);
        const res = await API.fetchLeague({
          members,
          filter,
        });
        setStandingItems(res.data);
        setLoadedItems(true);
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingItems(false);
      }
    },
    [currentRound, monthIndex]
  );

  const onLeagueSelect = (item) => {
    setSelectedLeague(item);
  };

  useEffect(() => {
    fetchLeagues();
  }, [fetchLeagues]);

  useEffect(() => {
    if (!selectedLeague) {
      setStandingItems([]);
      return;
    }
    const members = getLeagueMembers(selectedLeague);
    fetchLeague(members, selectedFilter);
  }, [selectedLeague, selectedFilter, fetchLeague]);

  const showModal = () => {
    setCreateLeagueModal(true);
  };

  const hideModal = (createdLeagueId) => {
    if (createdLeagueId !== null) fetchLeagues();
    setCreateLeagueModal(false);
  };

  const leaveLeague = async () => {
    const result = window.confirm(
      `Ви впевнені в тому, що хочете припинити участь у лізі ${selectedLeague.LeagueTitle}?`
    );
    if (result) {
      setLoadingLeagues(true);
      await API.updatePrivateLeague({
        leagueId: selectedLeague.LeagueId,
        leave: true,
      });
      fetchLeagues();
    }
  };

  const hasMe =
    standingItems.filter((item) => item.UserId === user?.me?.userId)?.length >
    0;

  const onTrashClick = async () => {
    const result = window.confirm(
      `Ви впевнені в тому, що хочете видалити лігу ${selectedLeague.LeagueTitle}?`
    );
    if (result) {
      setLoadingLeagues(true);

      await API.deletePrivateLeague(selectedLeague.LeagueId);
      window.location.reload();
    }
  };

  const filterDropdown = (
    <Dropdown className="private-league-filter">
      <Dropdown.Toggle
        disabled={!monthIndex || !currentRound}
        variant="success"
        className="private-league-filter-toggle"
      >
        <i className="bi bi-funnel"></i>&nbsp;
        {getFilterLabel(selectedFilter)}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ minWidth: 76 }}>
        {filters
          .filter((filter) => filter.type !== selectedFilter)
          .map((filter) => (
            <Dropdown.Item
              key={filter["type"]}
              onClick={() => setSelectedFilter(filter["type"])}
            >
              {filter["label"]}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <Page>
      <ScrollToTop />
      <div className="page-bar">
        <div
          className="inner"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="page-header">
            <div className="page-header-left">
              <BackButton />
              {isAddBtnVisible ? (
                <Button
                  size="sm"
                  onClick={showModal}
                  variant="danger"
                  style={{ marginLeft: 8, fontSize: "1.3rem" }}
                >
                  <i className="bi bi-plus-circle-fill"></i>&nbsp;Нова ліга
                </Button>
              ) : (
                <Spinner
                  variant="danger"
                  animation="grow"
                  size="sm"
                  style={{ marginLeft: 8 }}
                />
              )}
            </div>
            {hasMe && (
              <FindMe
                user={user}
                items={standingItems}
                setItems={setStandingItems}
              />
            )}
          </div>
        </div>
        <div>
          {leagues.length > 0 && (
            <div className="private-league-cnt">
              <div className="private-league-title">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Dropdown>
                    <Dropdown.Toggle
                      disabled={leagues.length < 2}
                      variant="warning"
                      className="private-league-dropdown"
                    >
                      <span className="private-league-title-text">
                        {selectedLeague?.LeagueTitle}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ minWidth: 76 }}>
                      {leagues.map(
                        (item, index) =>
                          selectedLeague.LeagueId !== item.LeagueId && (
                            <Dropdown.Item
                              key={index}
                              onClick={() => onLeagueSelect(item)}
                            >
                              {item.LeagueTitle}
                            </Dropdown.Item>
                          )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {selectedLeague.OwnerId === user?.me?.userId ? (
                  <div className="private-league-actions">
                    {filterDropdown}

                    <CopyToClipboard
                      text={getLeagueInviteUrl(selectedLeague.LeagueId)}
                      onCopy={() =>
                        alert("Лінк-запрошення скопійовано до буферу обміну")
                      }
                    >
                      <Button variant="warning" size="sm">
                        <i className="bi bi-share"></i>
                      </Button>
                    </CopyToClipboard>
                    <Button
                      onClick={onTrashClick}
                      style={{ marginLeft: 8 }}
                      variant="danger"
                      size="sm"
                    >
                      <i className="bi bi-trash lightText"></i>
                    </Button>
                  </div>
                ) : (
                  <div className="private-league-actions">
                    {filterDropdown}
                    <Button onClick={leaveLeague} variant="danger" size="sm">
                      <i className="bi bi-box-arrow-left lightText"></i>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {loadingItems ? (
        <Loader />
      ) : loadedItems ? (
        <StandingsView items={standingItems} marginTop={102} />
      ) : (
        <></>
      )}

      {!loadingItems &&
        !loadedItems &&
        !loadingLeagues &&
        leagues.length === 0 && (
          <div className="page-offset">
            <Message text={"У вас поки що немає приватних ліг"} />
          </div>
        )}
      <CreatePrivateLeague show={createLeagueModal} onHide={hideModal} />
    </Page>
  );
};

export default PrivateLeagues;
