import React from "react";
import Page from "../components/Page";
import ScrollToTop from "../components/ScrollToTop";
import { tgChatLink } from "../constants";
import { Button } from "react-bootstrap";
import BackButton from "../components/BackButton";

const Error = () => {
  return (
    <Page>
      <div className="page-bar">
        <div className="inner">
          <ScrollToTop />
          <div style={{ display: "flex", alignItems: "center" }}>
            <BackButton />
            <h4 className="bar-title">
              <i className="bi bi-wrench-adjustable"></i>&nbsp;Щось пішло не
              так...
            </h4>
          </div>
        </div>
      </div>
      <div className="page-offset">
        <p>
          Просимо вибачення, сталася помилка! Якщо вона повториться через деякий
          час, зверніться, будь ласка, у техпідтримку та надайте детальну
          інформацію щодо проблеми, з якою ви стикаєтеся.
        </p>
        <Button
          style={{ fontSize: "1.3rem" }}
          onClick={() => (window.location.href = tgChatLink)}
        >
          <i className="bi bi-headset"></i>&nbsp;Зв'язатися з нами
        </Button>
      </div>
    </Page>
  );
};

export default Error;
